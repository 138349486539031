import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const LogBookUsage = () => {
  return (
    <Layout>
      <Seo
        title="How to use Auto-tracking feature with Motion Detection"
        description={`This document serves as a comprehensive guide for end users on how to use the auto-tracking feature with motion detection in the Trip Logbook.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`logbook-usage`} />
    </Layout>
  )
}

export default LogBookUsage
